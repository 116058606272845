import { JobPreview, JobUserInfo } from '@borg/types';
import { isUndefined } from '@borg/utils';

export const useUserInfo = () => {
  const infos: Ref<Record<string, Partial<JobUserInfo>>> = useState('user-info', () => ({}));

  function getSaved(jobId: string) {
    return !isUndefined(infos.value[jobId]) && !isUndefined(infos.value[jobId].isSaved)
      ? infos.value[jobId].isSaved
      : undefined;
  }

  function setSaved(jobId: string, value: JobUserInfo['isSaved']) {
    infos.value = { ...infos.value, [jobId]: { ...infos.value[jobId], isSaved: value } };
  }

  function getSeen(jobId: string) {
    return !isUndefined(infos.value[jobId]) && !isUndefined(infos.value[jobId].isSeen)
      ? infos.value[jobId].isSeen
      : undefined;
  }

  function setSeen(jobId: JobPreview['id']) {
    infos.value = { ...infos.value, [jobId]: { ...infos.value[jobId], isSeen: true } };
  }

  function getApplied(jobId: string) {
    return !isUndefined(infos.value[jobId]) && !isUndefined(infos.value[jobId].appliedAt)
      ? infos.value[jobId].appliedAt
      : undefined;
  }

  function setApplied(jobId: string, applied = true) {
    infos.value = {
      ...infos.value,
      [jobId]: { ...infos.value[jobId], appliedAt: applied ? new Date() : null },
    };
  }

  return {
    getSaved,
    setSaved,
    getSeen,
    setSeen,
    getApplied,
    setApplied,
  };
};
